<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row>
      <v-col
        cols="12"
      >
        <v-text-field
          dense
          label="Description"
          v-model="registration.description"
          :counter="50"
          required
          hint="example of helper text only on focus"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
      >
        <v-text-field
          dense
          label="Status"
          v-model="registration.status"
          :counter="50"
          required
          hint="example of helper text only on focus"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
      >
        <v-text-field
          dense
          label="Expires"
          v-model="registration.expires"
          :counter="50"
          required
          hint="example of helper text only on focus"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
      >
        <p class="text-button">Provider *</p>
      </v-col>
      <v-col
        class="mt-n6"
        cols="12"
      >
        <v-card
          outlined
        >
          <v-col
            cols="12"
          >
            <v-row>
              <v-col
                cols="12"
                sm="2"
              >
                <!-- <div class="subtitle-1 text--secondary">Url *</div> -->
                <v-subheader>Url *</v-subheader>
              </v-col>
              <v-col
                v-if="registration.provider && registration.provider.url"
                cols="12"
                sm="10"
              >
                <v-text-field
                  dense
                  :counter="50"
                  :rules="commonRules"
                  required
                  hint="example of helper text only on focus"
                  v-model="registration.provider.url"
                ></v-text-field>
              </v-col>
              <v-col
                class="mt-n3"
                cols="12"
                sm="3"
              >
                <v-subheader>Forwarding Mode *</v-subheader>
              </v-col>
              <v-col
                cols="12"
                sm="9"
              >
                <v-btn-toggle
                  v-model="registration.provider.supportedForwardingMode"
                  rounded
                >
                  <v-btn
                    x-small
                    value="all"
                  >
                    All
                  </v-btn>
                  <v-btn
                    x-small
                    value="none"
                  >
                    None
                  </v-btn>
                  <v-btn
                    x-small
                    value="query"
                  >
                    Query
                  </v-btn>
                  <v-btn
                    x-small
                    value="update"
                  >
                    Update
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
      <v-col
        cols="12"
      >
        <p class="text-button">Data Provided *</p>
      </v-col>
      <v-col
        class="mt-n6"
        cols="12"
      >
        <v-card
          outlined
        >
          <v-col
            cols="12"
          >
            <v-row>
              <v-col
                cols="12"
                sm="2"
              >
                <v-subheader>Entities *</v-subheader>
              </v-col>
              <v-col
                cols="12"
                sm="10"
              >
                <v-row
                  v-for="(entities, entitiesIndex) in registration.dataProvided.entities"
                  :key="entitiesIndex"
                >
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-btn-toggle
                      mandatory
                      v-model="entities.key.name"
                      rounded
                    >
                      <v-btn
                        x-small
                        value="id"
                      >
                        id
                      </v-btn>
                      <v-btn
                        x-small
                        value="idPattern"
                      >
                        id-Pattern
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-btn-toggle
                      mandatory
                      v-model="entities.value.name"
                      rounded
                    >
                      <v-btn
                        x-small
                        value="type"
                      >
                        Type
                      </v-btn>
                      <v-btn
                        x-small
                        value="typePattern"
                      >
                        Type-Pattern
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      dense
                      label="Key *"
                      :counter="50"
                      :rules="entitiesIndex + 1 == registration.dataProvided.entities.length && entitiesIndex > 0 ? [] : commonRules"
                      required
                      hint="example of helper text only on focus"
                      v-model="entities.key.value"
                      @input="addDataProvidedEntites(entitiesIndex, entities.key.name, entities.value.name)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      dense
                      label="Value *"
                      :counter="50"
                      :rules="entitiesIndex + 1 == registration.dataProvided.entities.length && entitiesIndex > 0 ? [] : commonRules"
                      required
                      hint="example of helper text only on focus"
                      v-model="entities.value.value"
                      @input="addDataProvidedEntites(entitiesIndex, entities.key.name, entities.value.name)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="2"
              >
                <v-subheader>Attributes *</v-subheader>
              </v-col>
              <v-col
                cols="12"
                sm="10"
              >
                <v-combobox
                  class="mt-n4"
                  v-model="registration.dataProvided.attrs.values"
                  :search-input.sync="registration.dataProvided.attrs.inputValue"
                  hide-selected
                  label="Add attribute names"
                  multiple
                  persistent-hint
                  small-chips
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          Press <kbd>enter</kbd> to create a new one
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>
              <v-col
                cols="12"
                sm="2"
              >
                <v-subheader>Expression</v-subheader>
              </v-col>
              <v-col
                cols="12"
                sm="10"
              >
                <v-btn-toggle
                  v-model="registration.dataProvided.expressionz"
                  rounded
                >
                  <v-btn
                    x-small
                    value="georel"
                  >
                    Georel
                  </v-btn>
                  <v-btn
                    x-small
                    value="geometry"
                  >
                    Geometry
                  </v-btn>
                  <v-btn
                    x-small
                    value="coords"
                  >
                    Coords
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <!-- <v-col
                cols="12"
                sm="10"
              >
                <v-row
                  class="ml-2 mt-n4"
                  v-for="(exp, expIndex) in registration.dataProvided.expression"
                  :key="expIndex"
                >
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      dense
                      label="key"
                      :counter="50"
                      :rules="commonRules"
                      required
                      hint="example of helper text only on focus"
                      v-model="exp.key"
                      @input="addDataprovidedExpression(expIndex)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                  >
                    <v-text-field
                      dense
                      label="value"
                      :counter="50"
                      :rules="commonRules"
                      required
                      hint="example of helper text only on focus"
                      v-model="exp.value"
                      @input="addDataprovidedExpression(expIndex)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col> -->
            </v-row>
          </v-col>
        </v-card>
      </v-col>
      <v-col
        cols="12"
      >
        <p class="text-button">Forwarding Information</p>
      </v-col>
      <v-col
        class="mt-n6"
        cols="12"
      >
        <v-card
          outlined
        >
          <v-col
            cols="12"
          >
            <v-row>
              <v-col
                cols="12"
                sm="3"
              >
                <v-subheader>Times Sent</v-subheader>
              </v-col>
              <v-col
                cols="12"
                sm="9"
              >
                <v-text-field
                  disabled
                  dense
                  :counter="50"
                  :rules="commonRules"
                  required
                  hint="example of helper text only on focus"
                  v-model="registration.forwardingInformation.timesSent"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-subheader>Last Forwarding</v-subheader>
              </v-col>
              <v-col
                cols="12"
                sm="9"
              >
                <v-text-field
                  disabled
                  dense
                  :counter="50"
                  :rules="commonRules"
                  required
                  hint="example of helper text only on focus"
                  v-model="registration.forwardingInformation.lastForwarding"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-subheader>Last Failure</v-subheader>
              </v-col>
              <v-col
                cols="12"
                sm="9"
              >
                <v-text-field
                  disabled
                  dense
                  :counter="50"
                  :rules="commonRules"
                  required
                  hint="example of helper text only on focus"
                  v-model="registration.forwardingInformation.lastFailure"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="3"
              >
                <v-subheader>Last Success</v-subheader>
              </v-col>
              <v-col
                cols="12"
                sm="9"
              >
                <v-text-field
                  disabled
                  dense
                  :counter="50"
                  :rules="commonRules"
                  required
                  hint="example of helper text only on focus"
                  v-model="registration.forwardingInformation.lastSuccess"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-card>
      </v-col>
    </v-row>
    <small>*indicates required field</small>
  </v-form>
</template>

<script>
import api from '@/services/api'

var registrationz = {
  id: null,
  description: null,
  provider: {
    url: null,
    supportedForwardingMode: null
  },
  dataProvided: {
    entities: [{
      key: {
        name: null,
        value: null
      },
      value: {
        name: null,
        value: null
      }
    }],
    attrs: {
      values: [],
      inputValue: null
    },
    expression: [
      {
        key: null,
        value: null
      }
    ],
    expressionz: null
  },
  status: null,
  expires: null,
  forwardingInformation: {
    timesSent: null,
    lastForwarding: null,
    lastFailure: null,
    lastSuccess: null
  }
}

Object.freeze(registrationz)

export default {
  name: 'FiwareRegistrationForm',
  components: {
  },
  props: {
    registration: {
      type: Object,
      default () {
        return registrationz
      },
      required: false
    },
    id: {
      type: String,
      required: false,
      default () {
        return ''
      }
    }
  },
  data: () => ({
    valid: true
  }),
  methods: {
    addDataProvidedEntites: function (entitiesIndex, keyName, valueName) {
      if (this.registration.dataProvided.entities.length === entitiesIndex + 1) {
        this.registration.dataProvided.entities.push({ key: { name: keyName, value: null }, value: { name: valueName, value: null } })
      }
      if (!this.registration.dataProvided.entities[entitiesIndex].key.value && !this.registration.dataProvided.entities[entitiesIndex].value.value) {
        this.registration.dataProvided.entities = this.registration.dataProvided.entities.filter((value, index) => index !== entitiesIndex)
      }
    },
    addDataprovidedExpression: function (expIndex) {
      if (this.registration.dataProvided.expression.length === expIndex + 1) {
        this.registration.dataProvided.expression.push({ key: null, value: null })
      }
      if (!this.registration.dataProvided.expression[expIndex].key && !this.registration.dataProvided.expression[expIndex].value) {
        this.registration.dataProvided.expression = this.registration.dataProvided.expression.filter((value, index) => index !== expIndex)
      }
    },
    resetValidateForm: function () {
      this.$refs.form.resetValidation()
    },
    createRegistration: function () {
      api.post('/v2/registrations/', this.filterRegistrationForm, this.$store.state.firewareServices, '/test').then((response) => {
        if (response.status === 201) {
          console.log('Created :)')
          this.$emit('action', false)
        }
      })
    },
    updateRegistration: function () {
      api.patch(`/v2/registrations/${this.id}`, this.filterRegistrationForm, this.$store.state.firewareServices, '/test').then((response) => {
        if (response.status === 204) {
          console.log('Updated :)')
          this.$emit('action', false)
        }
      })
    }
  },
  computed: {
    commonRules: function () {
      return [
        v => !!v || 'field is required',
        v => (v && v.length <= 50) || 'field must be less than 50 characters'
      ]
    },
    iso8601Rules: function () {
      return [
        v => !!v || 'field is required',
        v => new Date(v).toJSON() === v || 'field not match ISO8601 format'
      ]
    },
    numberRules: function () {
      return [
        v => !!v || 'field is required',
        v => /^(-?\d+\.\d+)$|^(-?\d+)$/i.test(v) || 'field must be number'
      ]
    },
    filterRegistrationForm: {
      get () {
        const registration = {
          provider: {
            http: {
              url: this.registration.provider.url
            }
          },
          dataProvided: {
            entities: []
          }
        }
        if (this.registration.description) {
          registration.description = this.registration.description
        }
        if (this.registration.status) {
          registration.status = this.registration.status
        }
        if (this.registration.expires) {
          registration.expires = this.registration.expires
        }
        if (this.registration.provider.supportedForwardingMode) {
          registration.provider.supportedForwardingMode = this.registration.provider.supportedForwardingMode
        }
        if (this.registration.dataProvided.entities.filter(entitie => entitie.key.value && entitie.value.value).length > 0) {
          let entities = []
          this.registration.dataProvided.entities.filter(entitie => entitie.key.value && entitie.value.value).forEach(entitie => {
            entities.push({ [entitie.key.name]: entitie.key.value, [entitie.value.name]: entitie.value.value })
          })
          registration.dataProvided.entities = entities
        }
        if (this.registration.dataProvided.attrs.values.length > 0) {
          registration.dataProvided.attrs = this.registration.dataProvided.attrs.values
        }
        if (this.registration.dataProvided.expressionz) {
          registration.dataProvided.expression = this.registration.dataProvided.expressionz
        }
        if (this.registration.forwardingInformation.timesSent) {
          registration.forwardingInformation.timesSent = this.registration.forwardingInformation.timesSent
        }
        if (this.registration.forwardingInformation.lastForwarding) {
          registration.forwardingInformation.lastForwarding = this.registration.forwardingInformation.lastForwarding
        }
        if (this.registration.forwardingInformation.lastFailure) {
          registration.forwardingInformation.lastFailure = this.registration.forwardingInformation.lastFailure
        }
        if (this.registration.forwardingInformation.lastSuccess) {
          registration.forwardingInformation.lastSuccess = this.registration.forwardingInformation.lastSuccess
        }
        return registration
      }
    }
  },
  created () {
  }
}
</script>
