import { render, staticRenderFns } from "./FiwareRegistrationForm.vue?vue&type=template&id=d8a48bfc&"
import script from "./FiwareRegistrationForm.vue?vue&type=script&lang=js&"
export * from "./FiwareRegistrationForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VBtnToggle,VCard,VCol,VCombobox,VForm,VListItem,VListItemContent,VListItemTitle,VRow,VSubheader,VTextField})
